<template>
    <main>
        <div class="contenu">

      <div class="bravo-violet"><img src="../assets/images/bh-violet.png"></div>
       <div class="bravo-bleu">
          <img v-if="lot=='lot1'" src="/images/ballon-zoo.png">
          <img v-if="lot=='lot2'" src="/images/ballon-atelier-cuisine.png">
          <img v-if="lot=='lot3'" src="/images/ballon-coiffure.png">
          <img v-if="lot=='lot4'" src="/images/ballon-cultura.png">
          <img v-if="lot=='lot5'" src="/images/ballon-shooting.png">   
          <img v-if="lot=='perdu'" src="/images/ballon-perdu.png">                                                 
       </div>   
            <div style="position:relative;">
                <!-- mobile -->
                <div class="logo mobile">
                    <router-link to="/"><img src="../assets/images/logo.png"></router-link>
                </div>
                <img class="top-mobile mobile" src="../assets/images/top-vague4-mobile.jpg">
                <!-- commun -->
                <div class="top-bravo">
                 <img v-if="lot=='lot1'" src="/images/bravo-zoo.png">
                 <img v-if="lot=='lot2'" src="/images/bravo-atelier-cuisine.png">
                 <img v-if="lot=='lot3'" src="/images/bravo-coiffure.png">
                 <img v-if="lot=='lot4'" src="/images/bravo-cultura.png">
                 <img v-if="lot=='lot5'" src="/images/bravo-shooting-box.png">
                 <img v-if="lot=='perdu'" src="/images/bravo-photoweb.png">                                                                                   
                </div>
            </div>
            <div v-if="lot=='lot1'" class="bravo">
                <h2>C'est parti pour le fun !</h2>
                <h3>Pour récupérer votre cadeau :</h3>
                <p style="position:relative;z-index:19;">
                    <ul>
                        <li><span>1.</span> J’enregistre mon e-coffret dans mon espace personnel sur<a href="https://www.wonderbox.fr/" target="_blank">www.wonderbox.fr</a> pour retrouver toutes les activités</li>
                        <li><span>2.</span> Je choisis l’activité de mes rêves</li>
                        <li><span>3.</span> Je téléphone au partenaire choisi pour effectuer ma réservation</li>
                        <li><span>4.</span> Je donne ce billet électronique au partenaire le jour de la prestation</li>
                        <li><span>5.</span> Je profite ! Et partage mon expérience sur <a href="https://www.wonderbox.fr/" target="_blank">www.wonderbox.fr</a></li>
                    </ul>
                </p>
                <p >Si votre participation est conforme, un e-mail vous sera envoyé avec votre e-coffret et toutes ces informations.
                    Si vous ne l’avez pas reçu dans un délai maximum de 72 heures, pensez à vérifier vos courriers
                    indésirables.</p>

                <p style="position:relative;z-index:19;" class="little">Conditions d’utilisation de votre e-coffret Wonderbox :<br />
                    Le e-coffret Wonderbox reçu par email est valable jusqu’au 31/03/2024. Vous pouvez échanger ou
                    prolonger
                    gratuitement votre e-coffret, tant que sa date de validité n’est pas dépassée, et autant de fois que
                    vous le souhaitez, sauf cas particuliers visés à l’article 7 des conditions générales de vente.
                    Voir les termes et conditions complètes sur <a href="https://www.wonderbox.fr/cgv.html" target="_blank">www.wonderbox.fr/cgv.html.</a></p>
            </div>

            <div v-if="lot=='lot2'" class="bravo">
                <h2>C'est vous le chef !</h2>
                <h3>Pour récupérer votre cadeau :</h3>
                <p style="position:relative;z-index:19;">
                    <ul>
                        <li><span>1.</span> J’enregistre mon e-coffret dans mon espace personnel sur <a href="https://www.wonderbox.fr/" target="_blank">www.wonderbox.fr</a> pour retrouver
                            toutes les activités </li>
                        <li><span>2.</span> Je choisis l’activité de mes rêves </li>
                        <li><span>3.</span> Je téléphone au partenaire choisi pour effectuer ma réservation </li>
                        <li><span>4.</span> Je donne ce billet électronique au partenaire le jour de la prestation </li>
                        <li><span>5.</span> Je profite ! Et partage mon expérience sur <a href="https://www.wonderbox.fr/" target="_blank">www.wonderbox.fr</a> </li>
                    </ul>
                </p>
                <p>Si votre participation est conforme, un e-mail vous sera envoyé avec votre e-coffret et toutes ces informations.
                    Si vous ne l’avez pas reçu dans un délai maximum de 72 heures, pensez à vérifier vos courriers
                    indésirables.</p>

                <p style="position:relative;z-index:19;" class="little">Conditions d’utilisation de votre e-coffret Wonderbox :<br />
                    Le e-coffret Wonderbox reçu par email est valable jusqu’au 31/03/2024. Vous pouvez échanger ou
                    prolonger
                    gratuitement votre e-coffret, tant que sa date de validité n’est pas dépassée, et autant de fois que
                    vous le souhaitez, sauf cas particuliers visés à l’article 7 des conditions générales de vente.
                    Voir les termes et conditions complètes sur <a href="https://www.wonderbox.fr/cgv.html" target="_blank">www.wonderbox.fr/cgv.html</a>. </p>
            </div>            


            <div v-if="lot=='lot3'"  class="bravo">
                <h2>Un look de rêve !</h2>
                <h3>Pour récupérer votre cadeau :</h3>
                <p>
                    <ul>
                        <li><span>1.</span> Trouvez votre salon de coiffure Coiff&Co</li>
                        <li><span>2.</span> Rendez-vous directement dans votre salon</li>
                        <li><span>3.</span> Présentez votre bon cadeau</li>
                    </ul>
                </p>
                <p>Si votre participation est conforme, un e-mail vous sera envoyé avec  votre bon et toutes ces informations.
                    Si vous ne l’avez pas reçu dans un délai maximum de 72 heures, pensez à vérifier vos courriers
                    indésirables.</p>

                <p class="little">Conditions d’utilisation du bon Coiff&Co.<br />
                    Le bon reçu par email vous permet de bénéficier d'une séance de relooking coiffure comprenant un
                    forfait
                    shampooing, coupe, coiffage, en famille pour 4 personnes.
                    La durée de validité de votre bon est valable 1 fois en salon et non cumulable avec d’autres
                    promotions,
                    jusqu’au 30/06/2021.
                    Lors de votre visite dans l’un de nos salons, présentez votre bon imprimé ou en format
                    dématérialisé,
                    pour bénéficier de l'offre.</p>
            </div>


            <div v-if="lot=='lot4'" class="bravo">
                <h2>Assumez votre côté créatif !</h2>
                <h3>Pour récupérer votre cadeau :</h3>
                <p>
                    <ul>
                        <li><span>1.</span> Sélectionnez le magasin Cultura de votre choix</li>
                        <li><span>2.</span> Regardez le planning du magasin de votre choix</li>
                        <li><span>3.</span> Réservez et réglez en ligne avec votre e-carte cadeau ou rendez-vous en magasin pour
                            réserver
                            le créneau souhaité              <span style="color:black;!important;font-size:14px;">(sous réserve de disponibilité et des mesures de sécurité gouvernementales)</span>
             </li>
                    </ul>
                </p>
                <p>Si votre participation est conforme, un e-mail vous sera envoyé avec votre e-carte et toutes ces informations.
                    Si vous ne l’avez pas reçu dans un délai maximum de 72 heures, pensez à vérifier vos courriers
                    indésirables.</p>

                <p  class="little">Conditions d’utilisation de la e-carte cadeau Cultura :<br />
                    La e-carte cadeau Cultura reçue par email vous permet de régler un atelier créatif Session PARENT &
                    ENFANT
                    - Enfant de 4 à 6 ans.
                    La e-carte cadeau Cultura est valable jusqu’au 13/01/22. Elle est utilisable en une ou plusieurs
                    fois
                    dans tous les magasins Cultura et sur le site cultura.com (presse et billetterie uniquement en
                    magasin).
                    Les dotations sont non modifiables, non échangeables et non remboursables.</p>
            </div>


            <div v-if="lot=='lot5'" class="bravo">
                <h2>C'est le moment de faire votre plus beau sourire !</h2>
                <h3>Pour récupérer votre cadeau :</h3>
                <p style="position:relative;z-index:19;">
                    <ul>
                        <li><span>1.</span> Activez votre coffret en saisissant le code reçu par e-mail sur <a href="https://www.lashootingbox.com" target="_blank">www.lashootingbox.com</a>
                        </li>
                        <li><span>2.</span> Consultez la liste des photographes partenaires</li>
                        <li><span>3.</span> Réservez votre shooting</li>
                        <li><span>4.</span> Communiquez le numéro de votre bon de réservation au photographe</li>
                        <li><span>5.</span> Partagez votre expérience en évaluant votre cours photos</li>
                    </ul>
                </p>

                <p>Si votre participation est conforme, un e-mail vous sera envoyé avec votre code et toutes ces informations.
                    Si vous ne l’avez pas reçu dans un délai maximum de 72 heures, pensez à vérifier vos courriers
                    indésirables.</p>

                <p  style="position:relative;z-index:19;" class="little">Conditions d’utilisation du code LaShootingBOX :<br />
                    Le code reçu par email donne droit à une séance photo en famille d’une heure. Le code doit être
                    activé
                    sur le site <a href="https://www.lashootingbox.com" target="_blank">www.lashootingbox.com</a>.
                    Si le gagnant souhaite opter pour un autre e-coffret, il peut être échangé sur le site de
                    LaShootingBOX.
                    En revanche la date de validité ne changera pas. Le code est valable jusqu’au 30/06/2021.
                    Voir les termes et conditions complètes d’utilisation des e-coffrets sur
                    <a href="https://www.lashootingbox.com/fr/conditions-generales-de-vente" target="_blank">www.lashootingbox.com/fr/conditions-generales-de-vente</a>.</p>
            </div>

            <div v-if="lot=='perdu'" class="bravo">
                <h2>Immortalisez vos moments en famille !</h2>
                <h3>Pour récupérer votre cadeau :</h3>
                <p style="position:relative;z-index:19;">
                
                  <ul><li><span>1.</span> Rendez-vous sur <a href="https://www.photoweb.fr/PlayDoh65ans" target="_blank">www.photoweb.fr/PlayDoh65ans</a></li>
                  
                    <li><span>2.</span> Personnalisez votre livre photo avec vos beaux clichés</li>
                    <li><span>3.</span> A l’étape du panier, saisissez votre code unique reçu par e-mail</li>
                    <li><span>4.</span> Sélectionnez le mode de livraison et validez votre commande avant le 31/07/2021</li>
                    </ul></p>

                <p>Si votre participation est conforme, un e-mail vous sera envoyé avec votre code et toutes ces informations.
                    Si vous ne l’avez pas reçu dans un délai maximum de 72 heures, pensez à vérifier vos courriers
                    indésirables.</p>
                    
                <p style="position:relative;z-index:19;" class="little">Conditions d’utilisation du code Photoweb :<br />
                    Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021. 
                   
                    <!-- Le code reçu par email donne droit à 1 (un) Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou
                    -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay
                    comprise en
                    France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et
                    uniquement sur <a href="https://www.photoweb.fr/" target="_blank">Photoweb.fr</a>. Offre non cumulable avec d'autres offres promotionnelles en cours, non
                    compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer
                    (même nom, adresse mail et postale), jusqu'au 31/07/2021. --></p>
                    <br />&nbsp;
                    <br />&nbsp;
                    <br />
            </div>


                <div class="bottom-bravo mobile">
                    <img v-if="lot=='lot1'" src="/images/ballon-zoo.png">
                    <img v-if="lot=='lot2'" src="/images/ballon-atelier-cuisine.png">
                    <img v-if="lot=='lot3'" src="/images/ballon-coiffure.png">
                    <img v-if="lot=='lot4'" src="/images/ballon-cultura.png">
                    <img v-if="lot=='lot5'" src="/images/ballon-shooting.png">   
                    <img v-if="lot=='perdu'" src="/images/ballon-perdu.png">                                                                                   
                </div>
        </div>
    </main>
</template>

<script>
// lot1 wonderbox fun
// lot2 wonderbox atelier
// lot3 coiff & co
// lot4 cultura
// lot5 shooting box
// perdo photoweb

export default {

  name: 'Bravo',
  data() {
      return {
        lot: undefined
      };
    },
    mounted() {
        if(this.$route.params._lot) {
           this.lot =  this.$route.params._lot
        } else {
            console.log(window.lot)
            if(!window.lot) {
               this.$router.push("/");
            } else {
               this.lot = window.lot  
            }
        }
    }   
}
</script>
<style lang="scss">
.bravo {
    width: 800px;
    height: auto;
    margin: 0 auto;
    margin-top: -40px;
    background-image: url('../assets/images/fond-bravo.jpg');
    background-size: 100% 100%;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 40px;
    margin-bottom: 50px;

    @media screen and (max-width: 1440px) {
        margin-top: -12px;
        width: 650px;
    }

    @media screen and (max-width: 1024px) {
        margin-left: 5px;
        margin-top: -10px;
    }

    @media screen and (max-width: 768px) {
        background-image: url('../assets/images/fond-bravo-mobile.jpg');
        background-size: 100% 100%;
        background-position: unset;
        width: 100%;
        padding: 0px;
        margin-left: 0px;
        margin-top: -1%;
        padding-bottom:80px;
    }

    a {
        color: black;
    }

    p {
        padding: 5px;
        font-family: 'Myriad Pro Semibold';
        font-size: 16px;
        line-height: 17px;
        margin: 0;
        margin-top: 10px;

        @media screen and (max-width: 1440px) {
            font-size: 14px;
            line-height: 16px;
        }

        @media screen and (max-width: 768px) {
            padding:0 5%;
        }        
    }

    h2 {
        color: #22398D;
        font-family: 'Sound Bubble DEMO';
        font-size: 35px;
        line-height: 39px;
        width: 100%;
        text-align: center;

        @media screen and (max-width: 1440px) {
            font-size: 30px;
            line-height: 32px;
        }
        @media screen and (max-width: 768px) {
            padding:0 5%;
            width:90%;
            margin:0;
            padding-top:75px;
            font-size: 24px;
            line-height: 22px;
        }      
    }

    h3 {
        font-family: 'Myriad Pro Semibold';
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
        margin-bottom:20px !important;

        @media screen and (max-width: 1440px) {
            font-size: 14px;
            line-height: 16px;
        }

        @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 16px;
        }

         @media screen and (max-width: 768px) {
            padding:0 5%;
        }          
    }

    ul {
        margin-top: -20px;
        margin-left: -25px;
        list-style-type: none;

        li {
            width: 100%;

            span {
                color: #d71439;
            }
        }
    }
}

.little {
    font-family: 'Myriad Pro Light' !important;
    font-size: 14px !important;
    line-height: 16px;

    @media screen and (max-width: 1440px) {
        font-size: 12.9px !important;
        line-height: 14px !important;
    }
}
</style>